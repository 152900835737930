/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 19-11-2020.
 */
import React from 'react'

import './terms.scss'

import SEO from '../components/seo'
import MobileNavigation from '../components/MobileNavigation'
import Layout from '../components/layout'
import { withBreakpoints } from 'react-breakpoints'
import SubpageNavigation from '../components/SubpageNavigation'
import { CONTACT } from '../constant'

const terms = (props) => {
  return (
    <Layout>
      <SEO title="Studies&me" />

      {/*<div className="position-relative">
        <div className="position-absolute w-100" style={{ background: 'red', height: 70 }}></div>
      </div>*/}

      <MobileNavigation></MobileNavigation>

      {/*<SubpageNavigation></SubpageNavigation>*/}

      {/*<div className="container-fluid bg-primary terms" style={{}}>
        <div className="row ">
          <div className="col-12 d-flex justify-content-md-between " style={{ height: 70 }}>
            <div className="logo-terms mt-2 mt-md-1 d-flex flex-column justify-content-around">
              <div>
                <img style={{}} className="pt-2" src={sAndMeLogo} alt="" />
                <img
                  className="mt-n5 ml-1 pr-1 pr-md-0"
                  style={{ width: '1rem', height: 'auto' }}
                  src={trademark}
                  alt=""
                />
              </div>
              <div className="d-flex mt-2">
                <HeaderBtn className="mr-4" link="https://participate.studiesandme.com" title="Participate"></HeaderBtn>
                <HeaderBtn title="Contact"></HeaderBtn>
              </div>
            </div>
          </div>
        </div>
      </div>*/}
      <div className="container terms ">
        <div className="row" style={{}}>
          <div className="col-10 col-md-8 mx-auto mt-6">
            <h2 className="mb-6 mt-6">
              <b>Terms of use</b>
            </h2>
            <p>
              Studies&Me A/S is a private limited company incorporated in Denmark with CVR No. 41600020 ("Studies&Me").
              The terms “our”, “us” or “we” means Studies&Me A/S. For the purpose of these Terms of Use the following
              definitions shall be applicable:
            </p>
            <h4>Definitions and applicability of Terms</h4>
            <p>
              <b>Services</b> shall mean any mobile application or website made available by us.
            </p>
            <p>
              <b>Minor(s)</b> shall mean any person under the age of 18, or persons, who are not legally competent to
              enter into contracts under applicable law.
            </p>

            <p>
              <b>Content</b> shall mean any type of content, including but not limited to text, shared experiences,
              graphics, images, sound, music, software, audio, video, works of authorship of any kind, and information
              or other materials that are posted, generated, provided or otherwise made available through the Services
              and which may include Content which is owned or controlled by third parties which we are permitted to make
              available to you via the Services.
            </p>

            <p>
              <b>User Content</b>shall mean any Content that Account Holders provide to be made available through the
              Services.
            </p>

            <p>
              <b>User Account or Account</b> shall mean your personal account, with your personal associated username
              and password.
            </p>

            <p>
              <b>Account Holder </b> shall mean anyone creating an account in our Services - including you and your
              administrative user.
            </p>

            <p>
              <b>Intellectual Property (Rights)</b>
              shall mean any Content made available by us in Services, except for User Content, but including copyrights
              and trademark rights. Hereunder: trademarks, service marks, trade names, logos or functionalities.
              <br />
              The Services are in general not intended for Minors. By accessing the Services, you represent that you are
              at least 18 years of age and that you are otherwise legally qualified to enter into and form contracts
              under applicable law. If we become aware that a Minor has used Services which are not available to Minors,
              we will take steps to terminate the relating account and delete any data provided by the Minor.
              <br />
              Please read these Terms of Use carefully, as they govern the entire content of the Services, your use of
              the Services and information and services accessible and provided via the Services, including any
              correspondence between you and Studies&Me and/or any third party engaged by Studies&Me.
              <br />
              Because Studies&Me provides a wide range of Services, we may ask you to review and accept supplemental
              terms that apply to your interaction with a specific app, product, or service. To the extent those
              supplemental terms conflict with these Terms of Use, the supplemental terms associated with the Service
              governs.
            </p>

            <h4>2. Agreement to Terms </h4>
            <p>
              These Terms of Use are applicable to your use of Services, regardless of whether or not you choose to
              register with us. If you do not agree to these Terms of Use, please do not use the Services or cease using
              the Service immediately.
            </p>

            <h4>3. Changes to Terms of Use or the Service</h4>
            <p>
              We are continually improving our methods of communication. As technology evolves, new opportunities may
              arise for improving the Services by adding new features and functionalities. Therefore, we reserve the
              right to update these Terms of Use at any time and at our sole discretion. If and when our practices
              change and/or we update the Terms of Use, we will (as soon as possible) let you know either by posting the
              modified Terms of Use through the interface of the Services or through other communications. If you
              continue to use the Services after we have posted modified Terms of Use, you are indicating to us that you
              agree to be bound by the modified Terms of Use. If you don’t agree to be bound by the modified Terms of
              Use, then you should not use the Services after the date the modifications were made. When we revise the
              Terms of Use we will revise the date at the top of this page. If we according to applicable law are
              required to obtain a renewed consent, we will naturally comply with such obligations.
            </p>

            <h4>4. Informational Purposes</h4>
            <p>
              The Services - and Content provided by the Services - are given for general informational purposes only.
              The purpose of the Services is not to provide information about medicinal products or treatments
              subscribed by your doctor. For inquiries regarding products or devices, and specific medical treatments
              please contact your healthcare professional. We do not guarantee that you will experience better quality
              of life by using the Services. The Services are not intended for discussions regarding medicinal products
              or medical devices and posts made by you containing such information will not be replied upon. For
              inquiries regarding products or devices, please contact your healthcare professional.
            </p>

            <h4>5. Information provided "as is"</h4>
            <p>
              The information and Services provided by us are provided "as is" and, subject to these Terms of Use,
              Studies&Me makes no representations or warranties, expressed or implied, including but not limited to the
              implied warranties of merchantability, fitness for a particular purpose, non-infringement, reliability,
              accuracy of advice, comment and other information you may receive through the Services and the possible
              effects of such information, including on your condition. We endeavour to keep Content updated, however by
              using the Services you accept the risk that the information and Services provided by us may be incomplete
              or inaccurate or may not meet your requirements or be available on an uninterrupted, secure, or error-free
              basis. We make no warranty or representations regarding the quality, accuracy, timeliness, truthfulness,
              completeness, availability, functionality, compliance with applicable laws or reliability of any Content
              or other parts of the Services.
            </p>

            <h4>6. Not medical advice</h4>
            <p>
              The Services contains general nutritional, medical and pharmaceutical information for educational and
              informational purposes only, but it is not a substitute for medical judgement, advice, diagnosis,
              treatment, cure or prevention of any health condition, disease or problem. Services do not address all
              possible uses, actions, precautions, side effects, or interactions of drugs. You should consult with a
              healthcare professional prior to making any decisions, or undertaking any actions or not undertaking any
              actions related to any health care problem or issue. If you have any questions about the risks or benefits
              of taking a particular drug or about a specific health condition, you should consult a licensed healthcare
              professional. You should never disregard, avoid or delay obtaining medical advice from a licensed
              healthcare professional because of information in the Services.
            </p>
            <p>
              YOU SHOULD NEVER DISREGARD OR DELAY SEEKING MEDICAL ADVICE BECAUSE OF INFORMATION OBTAINED THROUGH YOUR
              USE OF THE PRODUCT. PLEASE FOLLOW UP, INDEPENDENTLY OF THE SERVICES, WITH YOUR PHYSICIAN, OR OTHER
              QUALIFIED HEALTH CARE PROFESSIONAL, TO DISCUSS ANY HEALTH CONCERNS OR ADVICE TAILORED TO YOUR MEDICAL OR
              HEALTH SITUATION. Studies&Me MAKES NO REPRESENTATIONS ABOUT AND ACCEPTS NO LIABILITY FOR THE SUITABILITY,
              RELIABILITY, IMPORTANCE OR ACCURACY OF THE INFORMATION CONVEYED VIA THE SERVICES OR ADVICE PROVIDED BY
              OTHER USERS THROUGH THE SERVICES. NO STATEMENTS MADE ON THE SERVICES HAVE BEEN EVALUATED BY ANY NATIONAL
              COMPETENT AUTHORITY.
            </p>

            <h4>7. Account responsibilities</h4>
            <p>
              If the Services require you to create a User Account, you must do so with an associated log in and
              password. You represent and warrant that all information you provide in association with your Account is
              current, complete and accurate, and that you will update this information as necessary to maintain its
              completeness and accuracy by visiting your personal profile. If you set up an Account, you are solely
              responsible for maintaining the confidentiality of your password, and for restricting access to your
              computer, mobile device etc. so that others may not access the password protected part of any applicable
              service. You accept responsibility for all activities that occur under your Account, or login. You agree
              to be responsible for any act or omission of any users who access the Services under your account or using
              your password (if any) that, if undertaken by you, would be deemed a violation of these Terms of Use, and
              that such act or omission shall be deemed a violation of these Terms of Use by you. Studies&Me may monitor
              the content displayed through your Account and profile in order to ensure the quality of data and input
              given when experiences are shared.
            </p>
            <h4>8. Your responsibility in using the Services</h4>
            <p>
              You agree not to use the Services in any manner that: (a) violates any local, provincial, national, or
              international law; (b) threatens, stalks, harasses, abuses, defames, slanders, or in any other way harms
              another individual or business organization; (c) collects or stores personal data about other users
              contrary to the applicable data protection and privacy laws, or otherwise prepares, compiles, uses,
              downloads, or copies any user information and/or usage information or any portion thereof, or transmits,
              provides or otherwise distributes (whether or not for a fee) such information to any third party contrary
              to the applicable laws; (d) impersonates any person or entity, misrepresents your affiliation with a
              person or entity, or otherwise creates a false identity for the purpose of misleading others; (e)
              infringes someone else’s patent, trademark, trade secret, copyright or other intellectual property or
              other rights; (f) is vulgar, gratuitously violent, obscene, pornographic, indecent, lewd, libelous,
              invasive of another’s privacy, or racially, ethnically, or otherwise offensive, hateful, or abusive; (g)
              advocates or solicits violence, criminal conduct, or the violation of any local, state, national, or
              international law or the rights of any third party; (h) promotes sexually explicit or pornographic
              material or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or
              age; (i) constitutes unsolicited or unauthorized advertising, junk or bulk email (spam), chain letters, or
              any other unsolicited commercial or non-commercial communication; or (h) transmits any material or
              communications that contain a petitions for signatures, chain letters, or letters relating to a pyramid
              scheme.
            </p>
            <p>
              You may not interfere with or disrupt the Services, or servers or networks connected to the Services,
              interfere with or disrupt or inhibit others’ use of the Services, including through the upload or other
              transmission of any software viruses, worms, time bombs, corrupted files, Trojan horses or any other
              computer code, files, or programs that are designed or intended to disrupt, damage, overburden, impair, or
              limit the functioning of any software, hardware, network, server, or communications systems or equipment
              or use any robot, spider, or other such programmatic or automatic device, including but not limited to
              automated dial-in or inquiry devices, to obtain information from the Services or otherwise monitor or copy
              any portion of the Services. Further, you may not (a) use any Studies&Me domain name as a pseudonymous
              return email address; (b) attempt to disable, bypass, modify, defeat, or otherwise circumvent any security
              related tools incorporated into the Services; (c) change, modify, adapt, or otherwise alter the Services,
              or change, modify, or alter another website so as to falsely imply that it is associated with the
              Services; (d) inject content or code, or otherwise alter or interfere with the way any content, source
              code or other element rendered or displayed in another user’s device; (e) reproduce, duplicate, copy,
              sell, or exploit for any commercial purposes, any portion of the Services or access to the Services; (f)
              systematically (including manually) collect, scrape, or use any content from the Services, including
              through the use of any data mining, or similar data gathering and extraction methods; (g) use any high
              volume, automated, or electronic means to access the services (including without limitation robots,
              spiders, or scripts); (h) frame the Services, place pop-up windows over its Content, or otherwise affect
              the display of its Content; (i) assist, encourage, or enable violations of these Terms of Use; (j)
              impersonate or misrepresent your affiliation with any person or entity; (k) intimidate, abuse, stalk,
              threaten, harass or violate the legal rights of any other user of the Product; (l) post or transmit any
              material that is defamatory, offensive, profane, indecent, hateful, threatening, obscene, or unlawful; or
              (m) upload, or otherwise make available, files that contain images, photographs, software or other
              material that is protected by intellectual property laws, including trademark and copyright laws unless
              you own or control the rights thereto or have obtained all necessary consents. You may not disobey any
              requirements, procedures, policies, or regulations of networks connected to the Services.
            </p>

            <h4>9. Intellectual Property Rights</h4>
            <p>
              All Content made available through the Services is the property of, or is licensed for use by, Studies&Me
              or other third parties and is protected by applicable legislation regarding intellectual property rights,
              including copyright laws and trademark laws. All intellectual property rights in or of the Services (other
              than in respect of User Content) belong to Studies&Me or one of our business partners. The Content may not
              be used other than for your personal and non-commercial use with all copyright or other proprietary
              notices retained. No un-authorised use, modification, copy or reproduction or retransmission of any part
              of the Content is permitted without the express prior written approval of Studies&Me. Any Intellectual
              Property displayed in the Services or otherwise used in connection with the Services are registered and
              unregistered trademarks or service marks and are protected worldwide. Nothing contained in the Services
              should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any
              Intellectual Property displayed in association with the Services without the prior written permission of
              Studies&Me or such third party that may own the Trademarks. Please be advised that Studies&Me will enforce
              its intellectual property rights to the fullest extent of the law, including the seeking of criminal
              prosecution.
            </p>

            <h4>10. Rights in Content Granted by Studies&Me</h4>
            <p>
              Subject to your compliance with these Terms of Use, Studies&Me grants you a limited, non-exclusive, non-
              transferable, non-sub-licensable license to use the Content solely in connection with your permitted use
              of the Services and solely for your personal and non-commercial purposes. Studies&Me may at any time
              revoke your license to use the Content. You understand and acknowledge that the Services are the property
              of Studies&Me, and you may not prepare derivative works of, modify, distribute, sell, lease, rent,
              sublicense, assign, export, or transfer in any other manner the Services, or any underlying software,
              technology, or other information, including any printed materials of the same, unless expressly authorized
              hereunder. Any use of third party software provided in connection with the Services will be governed by
              such third parties’ licenses and not by these Terms of Use.
            </p>

            <h4>11. Rights in User Content </h4>
            <p>
              If you share any User Content through the Services, you waive all moral rights and grant (or warrant that
              the owner of such materials expressly grants) to Studies&Me, its affiliates, its agents, its
              representatives and its third party service providers, a worldwide, perpetual, royalty-free, irrevocable,
              transferable, sub-licensable and non-exclusive license and right to: (i) use, copy, adapt, modify, adapt,
              transmit, communicate, publicly display, perform, and/or distribute such User Content; and (ii) create
              compilations and/or derivative works from such User Content. Studies&Me’s collection, use and disclosure
              of any personal information, or personal health information, will however, be subject to the terms of the
              Privacy Policy.
            </p>
            <p>
              You are solely responsible for all your User Content (including its reliability, accuracy and
              truthfulness) and Studies&Me has no control over the same. You confirm that you own all your User Content
              or you have all rights that are necessary to grant us the license rights in your User Content as described
              above. You also confirm that neither your User Content, nor your use and provision of your User Content to
              be made available through the Services will infringe, misappropriate or violate a third party’s
              intellectual property rights, or rights of publicity or privacy, or result in the violation of any
              applicable law or regulation.
            </p>
            <p>
              Subject to the section titled “Limitation of Liability” Studies&Me will have no liability in this respect
              and we have the right to disclose your identity to any third party claiming that any User Content
              submitted by you constitutes a violation of their intellectual property rights or of their right to
              privacy.
            </p>
            <p>
              Studies&Me has the right to refuse to post, move, remove or edit any User Content from the Services at our
              own discretion but we do not assume any obligation to any users to monitor the User Content or remove any
              specific material. You can remove your User Content by specifically deleting it.
            </p>

            <p>
              If you comment or submit a comment or recommendation on the Services, Studies&Me may contact you regarding
              the content of the comment, subject to the applicable data protection, privacy and direct marketing laws.
              Subject to your consent, you may also choose to grant Studies&Me license to modify, edit, crop, highlight
              and/or republish your content with attribution to you. If requested by you or if you do not wish to grant
              your consent, Studies&Me may publish your content without attribution to you in an anonymous form.
            </p>

            <h4>12. Link to Third party Websites</h4>
            <p>
              The Services may contain links to third-party websites or content over which we have no control. We
              provide these links only as a convenience and not as an endorsement of such websites or the content
              thereof. Studies&Me is not responsible for the content, products or services available from those websites
              or resources/content or links displayed on such websites. You acknowledge sole responsibility for, and
              assume all risk arising from, your use of any third-party websites or resources. If you follow a link to
              any of these websites, please be aware that these websites may have their own terms of use and privacy
              policies and that we do not accept responsibility for these policies. Please check these policies before
              you use or submit any personal data on these websites.
            </p>

            <h4>13. Sites linking to us</h4>
            <p>
              Studies&Me does not endorse third party websites referencing or linking to the Services and is not
              responsible for content on these linked sites or for control over information that users may choose to
              provide to these sites.
            </p>

            <h4>14. Termination</h4>
            <p>
              Studies&Me may terminate your use of the Services (in whole or in part) for any reason at any time. You
              may cancel your Account at any time by sending an email to us at contact@studiesandme.com. Upon any
              termination, discontinuation or cancellation of services or your Account, all provisions of these Terms of
              Use which by their nature should survive will survive, including, without limitation, ownership
              provisions, warranty and professional/medical services disclaimers, assumption of risk and compensation,
              limitations of liability, and provisions regarding governing law and venue. You understand that
              termination of these Terms and your Account may involve deletion of your information from our databases as
              well as any User Content.
            </p>
            <p>
              TO THE EXTENT PERMITTED BY LAW, YOU AGREE THAT WE WILL NOT BE LIABLE TO YOU OR ANY OTHER PARTY FOR ANY
              TERMINATION OF YOUR ACCESS TO THE SITE OR DELETION OF YOUR ACCOUNT OR USER CONTENT.
            </p>
            <h5>15. Compensation</h5>
            <p>
              To the extent permitted by law, you agree to indemnify, defend and hold harmless Studies&Me, its parents,
              subsidiaries, affiliates, officers, directors, licensors, co-branders, suppliers, and other partners,
              employees, consultants and agents, together with all of their respective officers, directors, employees
              and consultants, from and against any and all claims, liabilities, damages, losses, costs, expenses, fees
              (including reasonable attorneys’ fees and court costs) that such parties may incur as a result of or
              arising from (a) any information you submit, post, or transmit through the Services, (b) your use of the
              Services, (c) your failure to observe or perform any material provision in or violation of these Terms of
              Use, (d) your violation of any rights of any other person or entity or (e) any viruses, Trojan horses,
              worms, time bombs, cancelbots, or other similar harmful or deleterious programming routines input by you
              into the Services. We shall provide notice to you of any such claim, suit, or proceeding and shall assist
              you, at your expense, in defending any such claim, suit, or proceeding. We reserve the right to
              exclusively defend and control any such indemnification matters; and that you will fully cooperate with us
              in any such defense.
            </p>

            <h4>16. Security</h4>
            <p>
              Actual or attempted unauthorized use of the Services, or any services provided by the Services or the
              Content may result in criminal and/or civil prosecution, if relevant. Studies&Me explicitly reserves the
              right to view, monitor, and record activity relating to your use of the Services, without notice or
              permission from you. Any information obtained by monitoring, reviewing, or recording is subject to review
              by law enforcement organizations in connection with investigation or prosecution of possible illegal
              activity in connection with the Services.
            </p>

            <p>
              Studies&Me will also comply in good faith with all court orders as well as all legitimate law enforcement
              and regulatory inquiries involving requests for such information. Studies&Me specifically reserves the
              right to monitor your use of the Services, including any associated Content, and to remove or modify any
              Content and/or User Content, information, or any other element associated with the Product, in its sole
              and absolute discretion, for any reason or no reason, without any notification or explanation.
            </p>

            <p>
              While we use security measures to protect personal information, including any sensitive personal data,
              transmitted online, please be aware that no security measures are perfect or impenetrable.
            </p>

            <p>
              In particular, please know that communication by SMS text messages is inherently insecure and may be
              intercepted by an unauthorized third party. Any information conveyed via SMS text message is sent at your
              own risk.
            </p>

            <p>
              We will take all reasonable measures to protect the Services against computer viruses, worms, Trojan
              horses or other threats. We do not, however, warrant that the Services are free from such harmful programs
              and we are not responsible for any loss incurred by you due to harmful programs received from the Services
              or by means of files downloaded from the Services.
            </p>

            <h4>17. Warranty disclaimer</h4>
            <p>
              STUDIES&ME, ITS AFFILIATES, AND ITS AND THEIR OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS MAKES NO EXPRESS
              WARRANTIES OR REPRESENTATIONS ABOUT THE SERVICES, CONTENT OR FUNCTIONALITIES OF THE SERVICES, OR THE
              CONTENT HEREOF. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU
              SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE. YOU EXPRESSLY AGREE THAT USE OF THE
              SERVICES IS AT YOUR SOLE RISK. THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. TO THE
              MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, STUDIES&ME EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND,
              WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR
              A PARTICULAR USE OR PURPOSE, NON-INFRINGEMENT, TITLE, OPERABILITY, CONDITION, QUIET ENJOYMENT, VALUE,
              SECURITY, RELIABILITY, ACCURACY OF DATA AND SYSTEM INTEGRATION. STUDIES&ME MAKES NO WARRANTY THAT THE
              SERVICES WILL MEET YOUR REQUIREMENTS, OR THAT THE SERVICES WILL BE ACCURATE, RELIABLE, CORRECT, USEFUL,
              TIMELY, UNINTERRUPTED, SECURE, DEFECT-FREE, OR ERROR-FREE (INCLUDING, WITHOUT LIMITATION, FREE FROM
              VIRUSES, WORMS, TROJANS, OTHER MALICIOUS CODE, OR OTHER HARMFUL COMPONENTS); NOR DOES STUDIES&ME MAKE ANY
              WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES, OR THAT DEFECTS IN THE
              SERVICES WILL BE CORRECTED. YOU UNDERSTAND AND AGREE THAT ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED
              THROUGH THE USE OF THE SERVICES IS DONE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY
              RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SERVICES OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF
              SUCH MATERIAL AND/OR INFORMATION. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM
              THE STUDIES&ME OR THROUGH THE SERVICES WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN. IN
              JURISDICTIONS NOT ALLOWING THE EXCLUSION OR LIMITATION OF CERTAIN WARRANTIES, OUR AND OUR SUBSIDIARIES,
              AFFILIATES, LICENSORS, AND SUPPLIERS LIABILITY WILL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
            </p>

            <h4>18. Limitation of Liability</h4>
            <p>
              IN NO EVENT SHALL Studies&MeBE LIABLE UNDER THESE TERMS OF USE FOR ANY LOSS OR DAMAGE CAUSED BY US, OUR
              AFFILIATES, AGENTS, LICENCERS AND/OR SUPPLIERS IN CIRCUMSTANCES WHERE: (A) THERE IS NO BREACH OF LEGAL
              DUTY OF CARE OWED TO YOU BY US; (B) SUCH LOSS OR DAMAGE IS NOT A REASONABLY FORESEEABLE RESULT OF ANY SUCH
              BREACH; AND (C) ANY INCREASE IN LOSS OR DAMAGE RESULTS FROM A BREACH BY YOU OF THESE TERMS OF USE.
              STUDIES&ME’S LIABILITY SHALL NOT IN ANY EVENT INCLUDE LOSSES RELATED TO YOU SUCH AS LOST PROFITS, LOSS OF
              USE OF PROFIT, LOST DATA OR BUSINESS INTERRUPTION. NOTHING IN THESE TERMS OF USE SHALL LIMIT OR EXCLUDE
              OUR LIABILITY FOR DEATH OR PERSONAL INJURY RESULTING FROM OUR NEGLIGENCE OR NEGLIGENCE OF OUR EMPLOYEES,
              AFFILIATES, CONTRACTORS OR AGENTS.
            </p>
            <p>
              YOU ACKNOWLEDGE AND AGREE THAT TO THE EXTENT PERMITTED BY LAW IN NO EVENT WILL STUDIES&ME OR ITS OFFICERS,
              EMPLOYEES, DIRECTORS, PARENTS, SUBSIDIARIES, AFFILIATES, AGENTS, LICENSORS, AND/OR SUPPLIERS BE LIABLE FOR
              ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY, PUNITIVE, OR OTHER DAMAGES, INCLUDING, BUT
              NOT LIMITED TO, DAMAGES FOR LOSS OF REVENUES, PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES
              (EVEN IF SUCH PARTIES WERE ADVISED OF, KNEW OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES,
              AND NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY), ARISING OUT OF OR RELATED TO
              YOUR USE OF THE SERVICES, REGARDLESS OF WHETHER SUCH DAMAGES ARE BASED ON CONTRACT, TORT (INCLUDING
              NEGLIGENCE AND STRICT LIABILITY), WARRANTY, STATUTE, OR OTHERWISE AND EVEN IF WE AND/OR OUR SUBSIDIARIES,
              AFFILIATES, LICENSORS, AND/OR SUPPLIERS HAS OR HAVE BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. IF YOU ARE
              DISSATISFIED WITH ANY PORTION OF THE SERVICES, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE
              SERVICES. THE AGGREGATE LIABILITY OF STUDIES&ME TO YOU FOR ALL CLAIMS ARISING FROM OR RELATED TO THE
              SERVICES OR THE SERVICES IS LIMITED TO THE LESSER OF (I) THE AMOUNT OF FEES ACTUALLY PAID BY YOU FOR USE
              OF THE SERVICES.
            </p>
            <p>
              Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of
              liability for incidental or consequential damages. Accordingly, some of the above limitations and
              disclaimers may not apply to you. To the extent that we may not, as a matter of applicable law, disclaim
              any implied warranty or limit our liabilities, the scope and duration of such warranty and the extent of
              our liability will be the minimum permitted under such applicable law.
            </p>

            <h4>19 Feedback</h4>
            <p>
              We welcome your feedback, questions, comments and suggestions for improvement of the Services. You can
              provide us with feedback by emailing us at contact@studiesandme.com. Any feedback, questions, comments and
              suggestions including any ideas, inventions, concepts, techniques or know how forwarded to Studies&Me will
              become the property of Studies&Me and may without restrictions be used for any purposes including
              developing, manufacturing and marketing goods or services, to the extent such use is permitted under the
              applicable laws. Received comments will not be treated as confidential, unless specifically requested so
              by the sender.
            </p>

            <h4>20.General terms </h4>
            <p>
              These Terms of Use constitute the entire and exclusive understanding and agreement between Studies&Me and
              you regarding the Services and content of the Services, and supersede and replace any and all prior oral
              or written understandings or agreements between Studies&Me and you regarding the Services. If for any
              reason a court of competent jurisdiction finds any provision of these Terms of Use is found to be illegal,
              void, or unenforceable, the unenforceable provision will be modified so as to render it enforceable and
              effective to the maximum extent possible and the other provisions of these Terms of Use will remain in
              full force and effect.
            </p>

            <h4>Governing law and venue</h4>

            <p>
              These Terms of Use and your access to and use of the Services and the Content hereof shall be governed by
              and interpreted in accordance with the Laws of Denmark. Any dispute arising out of or in relation to these
              Terms of Use shall, if it cannot be solved amicably, be decided by the Danish Courts in Copenhagen,
              Denmark. Laws and regulatory requirements vary across countries and jurisdictions. The Services may in
              whole or in part not comply with legal requirements of all jurisdictions. You acknowledge and agree that
              if you are located in any jurisdiction in the world where any common, statutory, regulatory, codified, or
              other law, rule, or regulation makes accessing the Services or any material inappropriate or illegal, or
              subject to consents or permissions that you yourself have not obtained, or voids these Terms of Use in
              whole or in part, then you are not authorized to access the Services. Any portion of the Services is void
              where and to the extent prohibited by law.
            </p>

            <h4>Special terms for US</h4>
            <p>
              Except to the extent otherwise provided by law, any dispute or claim arising out of or in any way relating
              to this Website or the Services shall be finally resolved by arbitration. The arbitration shall be
              conducted in accordance with the International Institute for Conflict Prevention and Resolution (“CPR”)
              Rules for Non- Administered Arbitration (Effective November 1, 2007), except as they may be modified
              herein or by mutual agreement of the parties. The arbitration shall take place in New Jersey or such other
              location as agreed to by the parties. Notwithstanding the foregoing, the parties consent to the
              jurisdiction of the federal or state courts having jurisdiction in the location where the arbitration is
              conducted as to judicial proceedings relating to any aspect of the arbitration, including motions to
              confirm, vacate, modify or correct an arbitration award. The arbitration shall be governed by the Federal
              Arbitration Act, 9 U.S.C. § 1, et seq., and judgment upon the award may be entered by any court having
              jurisdiction thereof or having jurisdiction over the relevant party or its assets. The arbitration shall
              be conducted by one arbitrator, who shall be selected by agreement of the parties or, failing such
              agreement within 30 days after the initiation of the arbitration, by the CPR. The parties shall be
              responsible for paying the costs of the arbitration in accordance with CPR rules. The parties agree that
              the arbitration shall be kept confidential and that the existence of the proceeding and any element of it
              shall not be disclosed beyond the tribunal, the parties and their counsel, and any person necessary to the
              conduct of the proceeding. The confidentiality obligations shall not apply if disclosure is required by
              law or in judicial or administrative proceedings, or to the extent that disclosure is necessary to enforce
              the rights arising out of the award, provided that the parties agree to use best efforts to keep such
              disclosure confidential and agree, subject to court approval, to submit such disclosure to a court only
              under seal. Claims may not be brought in the arbitration proceeding by or on behalf of a purported class
              of claimants who are not parties to this Website or the Services. This agreement to arbitrate shall
              constitute an irrevocable waiver of each party’s right to a trial by jury, as well as of rights to
              discovery or to an appeal that would customarily be available in a judicial proceeding but that may be
              limited or unavailable in connection with such an arbitration. To the extent that this arbitration
              requirement is found to be entirely unenforceable, you agree that any dispute arising from or relating to
              this Website or the Services will be brought exclusively in a court of competent jurisdiction, federal or
              state, located within the State of New York, and in no other jurisdiction, and you hereby consent to
              personal jurisdiction and venue in, and agree to service of process issued or authorized by, such court.
            </p>

            <h4>23. Contact Information </h4>
            <p>
              If you have any questions about these Terms of Use or the Services, please contact Studies&Me at: <br />
              Studies&Me A/S <br />
              {CONTACT.address}, ,
              <br />
              {CONTACT.zip} {CONTACT.city} {CONTACT.country},
              <br />
              E-mail : {CONTACT.email},
              <br />
              Phone number: +45 3111 0006.
            </p>
          </div>
        </div>
        <div className="row" style={{}}>
          <div className="col-12" style={{ height: '5rem' }}></div>
        </div>
      </div>
    </Layout>
  )
}

export default withBreakpoints(terms)
